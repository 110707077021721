<template>
   <v-container class="unreactive-margins">
    <v-data-table
      :headers="headers"
      :items="haulRequests"
      class="inverse-header-color"
      item-key="id"
      ref="haulsTable"
      :expanded.sync="expanded"
   >
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr :class="`${isExpanded ? 'expanded-row' : ''}`">
          <td class="d-block d-sm-table-cell" v-for="header in headers" :key="`row-${item.id}-field-${header.value}}`">
            <template v-if="header.value == 'haulCode'">
              <span class="font-weight-bold">{{ item.code }}</span>
            </template>
            <template v-else-if="header.value == 'haulingContractor'">
              {{ item.haulingCompanyProfile && item.haulingCompanyProfile.company ? item.haulingCompanyProfile.company.legalName : '-' }}
            </template>
            <template v-else-if="header.value == 'siteManager'">
              <template v-if="item.isOwner || isHaulingManager">
                <template v-if="item.isExport">
                  {{ item.exportingContractorCompanyProfile && item.exportingContractorCompanyProfile.company ? item.exportingContractorCompanyProfile.company.legalName : '-' }}
                </template>
                <template v-else>
                  {{ item.importingContractorCompanyProfile && item.importingContractorCompanyProfile.company ? item.importingContractorCompanyProfile.company.legalName : '-' }}
                </template>
              </template>
              <template v-else>
                <template v-if="item.isExport">
                  {{ item.importingContractorCompanyProfile && item.importingContractorCompanyProfile.company ? item.importingContractorCompanyProfile.company.legalName : '-' }}
                </template>
                <template v-else>
                  {{ item.exportingContractorCompanyProfile && item.exportingContractorCompanyProfile.company ? item.exportingContractorCompanyProfile.company.legalName : '-' }}
                </template>
              </template>
            </template>
            <template v-else-if="header.value == 'site'">
              {{ item.segmentDepth && item.segmentDepth.segment && item.segmentDepth.segment.site ? `${item.segmentDepth.segment.site.name} - ${item.segmentDepth.segment.name} - ${item.segmentDepth.name}` : '-' }}
            </template>
            <template v-else-if="header.value == 'segmentDepth'">
              {{ item.segmentDepth && item.segmentDepth.segment ? `${item.segmentDepth.code.split('-')[1]} - ${item.segmentDepth.code.split('-')[2]}` : '-' }}
            </template>
            <template v-else-if="header.value == 'expand'">
              <a @click="expandRow(item, expand, isExpanded)">
                <v-icon color="primary" v-if="!item.expanded">mdi-chevron-right</v-icon>
                <v-icon color="primary" v-else>mdi-chevron-down</v-icon>
              </a>
            </template>
            <template v-else-if="header.value == 'clientType'">
              <div class="d-flex justify-center">
                <v-chip small :color="chipColor(clientType(item))" class="font-weight-bold">{{ clientType(item).toUpperCase() }}</v-chip>
              </div>
            </template>
            <template v-else-if="header.value == 'status'">
              <div class="d-flex justify-center">
                <v-chip small :color="chipColor(clientStatus(item))">{{ clientStatus(item) }}</v-chip>
              </div>
            </template>
            <template v-else-if="header.value == 'allocatedTotals'">
                <!--total volumes are aggregated by unit of measurement. For now it's only cubit meters, but may be other in the future. -->
                <div v-for="(totalVolume, totalVolumeIndex) in item.totalVolume" :key="`hr-${item.id}-uom-${totalVolume.unitOfMeasurement.id}`">
                  <br v-if="totalVolumeIndex > 0" />
                  <v-tooltip v-if="totalVolume.allocatedVolume < totalVolume.acceptedVolume" top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="display: inline;">
                      <span class="error--text">
                        {{ totalVolume.allocatedVolume }}
                      </span>

                      </div>
                    </template>
                    <span> Not all volume has been allocated </span>
                  </v-tooltip>
                  <v-tooltip v-else-if="totalVolume.allocatedVolume > totalVolume.importingVolume" top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on" style="display: inline;">
                      <span class="warning--text">
                        {{ totalVolume.allocatedVolume }}
                      </span>

                      </div>
                    </template>
                    <span> Not all approvals have been accepted </span>
                  </v-tooltip>
                  <span v-else :class="'success--text'">{{ totalVolume.allocatedVolume }}</span>
                  / {{ totalVolume.acceptedVolume }} {{ totalVolume.unitOfMeasurement.shortName }}
                </div>

            </template>
            <template v-else-if="header.value == 'createdOn'">
              {{ formatDate(item.createdOn) }}
            </template>
            <template v-else-if="header.value == 'note'">
              <v-tooltip v-if="item.note" left>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon>mdi-message</v-icon>

                  </div>
                </template>
                <span> {{ item.note }} </span>
              </v-tooltip>
            </template>
            <template v-else-if="header.value == 'actions'">
              <div
                v-if="showNewApprovalButton(item, importHaulRequestTypeId)"
                class="d-flex justify-end"
                >
                <v-btn
                  v-if="item.totalVolume.length == 1"
                  @click="addApproval(item, false)"
                  :disabled="disabledNewApprovalButton(item, importHaulRequestTypeId)"
                  outlined
                  small
                  color="success"
                  >+ new import approval</v-btn>
              </div>
              <div
                v-if="showNewApprovalButton(item, exportHaulRequestTypeId)"
                class="d-flex justify-end">
                <v-btn
                  @click="addApproval(item, true)"
                  :disabled="disabledNewApprovalButton(item, exportHaulRequestTypeId)"
                  outlined
                  small
                  color="success"
                  >+ new export approval</v-btn>
              </div>
            </template>
            <template v-else>
              {{item[header.value]}}
            </template>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <tr v-if="item.expanded" class="expanded-row-content">
          <td :colspan="headers.length">
            <div class="ma-4 ml-10 mr-10" style="border: 1px #7E7E7E solid">
              <v-tabs v-if="selectedCompanyProfile.companyTypeId === haulingCompanyTypeId" v-model="item.selectedTab"> <!-- -->
                <v-tab v-for="tab in item.tabs" :key="`hr-${item.id}-tab-${tab}`">{{ tab }}</v-tab>
              </v-tabs>
              <haul-request-approval-table
                v-if="item.tabs[item.selectedTab] == 'Exports'"
                :approvals="item.exportApprovals"
                :haulRequest="item"
                type="export"
                @processApproval="(approval, statusId, decisionNote) => processApproval(approval, statusId, decisionNote)"
                @sendApproval="(approval) => sendApproval(approval)"
                @selectDepthAndApprove="(approval, callback) => selectDepthAndApprove(approval, callback)"
                @editApproval="(approval) => editApproval(approval)"/>
              <haul-request-approval-table
                v-if="item.tabs[item.selectedTab] == 'Imports'"
                :approvals="item.importApprovals"
                :haulRequest="item"
                type="import"
                @processApproval="(approval, statusId, decisionNote) => processApproval(approval, statusId, decisionNote)"
                @sendApproval="(approval) => sendApproval(approval)"
                @selectDepthAndApprove="(approval, callback) => selectDepthAndApprove(approval, callback)"
                @editApproval="(approval) => editApproval(approval)" />
            </div>
          </td>
        </tr>
      </template>
   </v-data-table>
  </v-container>
</template>

<script>
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
import HaulRequestApprovalTable from './HaulRequestApprovalTable.vue'
import { dateFromUTC } from '@/helpers/FormatHelper.js'

export default {
  components: { HaulRequestApprovalTable },
  props: {
    haulRequests: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    haulRequests: {
      handler () {
        this.syncExpanded()
      }
    }
  },
  data: () => ({
    expanded: [],

    siteManagementCompanyTypeId: 1,
    haulingCompanyTypeId: 2,
    newApprovalStatusId: 1,
    acceptApprovalStatusId: 2,
    declineApprovalStatusId: 3,
    generalContractorCompanyTypeId: 1,
    exportHaulRequestTypeId: 1,
    importHaulRequestTypeId: 2
  }),
  computed: {
    ...mapState({
      companies: (state) => state.comp.companies,
      selectedSiteId: (state) => state.site.selectedSiteId
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    isGeneralContractor () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.generalContractorCompanyTypeId
    },
    isHaulingManager () {
      const companyProfile = this.selectedCompanyProfile
      return companyProfile && companyProfile.companyTypeId === this.haulingCompanyTypeId
    },
    headers () {
      const headers = []
      headers.push({ text: '', value: 'expand', width: '20px', sortable: false })
      headers.push({ text: 'Haul Request', value: 'haulCode', sortable: false })
      if (this.selectedCompanyProfile.companyTypeId !== this.haulingCompanyTypeId) headers.push({ text: 'Hauling Contractor', value: 'haulingContractor', sortable: false })
      if (this.selectedCompanyProfile.companyTypeId === this.haulingCompanyTypeId) headers.push({ text: 'Site Management', value: 'siteManager', sortable: false })
      headers.push({ text: 'Type', value: 'clientType', sortable: false, align: 'center' })
      headers.push({ text: 'Site', value: 'site', sortable: false })
      headers.push({ text: 'Segment - Depth', value: 'segmentDepth', sortable: false })
      headers.push({ text: 'Status', value: 'status', sortable: false, align: 'center' })
      // headers.push({ text: 'Load QTY (Used / Total)', value: 'loadTotals', sortable: false })
      if (this.selectedCompanyProfile.companyTypeId === this.haulingCompanyTypeId) headers.push({ text: 'Allocated Load', value: 'allocatedTotals', sortable: false })
      headers.push({ text: 'Note', value: 'note', sortable: false })
      headers.push({ text: 'Created On', value: 'createdOn', sortable: false })
      headers.push({ text: 'Actions', value: 'actions', align: 'end', sortable: false })
      return headers
    }
  },
  methods: {
    viewInformation (item) {
      this.$emit('viewInformation', item)
    },
    viewSegment (item) {
      this.$emit('viewSegment', item)
    },
    viewInfo (item) {
      this.$emit('viewInfo', item)
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    siteType (site) {
      if (site.isDonor && site.IsReceiver) return 'Hybrid'
      if (site.isDonor) return 'Exporter'
      if (site.IsReceiver) return 'Importer'
      return null
    },
    expandRow (item, expandCallback, isExpanded) {
      expandCallback(!isExpanded)
      item.expanded = !isExpanded
    },
    addApproval (haulRequest, isExport) {
      this.$emit('openApprovalForm', haulRequest, isExport)
    },
    processApproval (approval, statusId, decisionNote) {
      this.$emit('processApproval', approval, statusId, decisionNote)
    },
    sendApproval (approval) {
      this.$emit('sendApproval', approval)
    },
    editApproval (approval) {
      this.$emit('editApproval', approval)
    },
    selectDepthAndApprove (approval, callback) {
      this.$emit('selectDepthAndApprove', approval, (res) => callback(res))
    },
    clientStatus (haulRequest) {
      if (this.selectedCompanyProfile.companyTypeId === this.generalContractorCompanyTypeId && haulRequest.siteId !== this.selectedSiteId) {
        const acceptedCount = (haulRequest.typeId === this.exportHaulRequestTypeId ? haulRequest.importApprovals : haulRequest.exportApprovals).filter(approval => approval.haulRequestApprovalStatusId === this.acceptApprovalStatusId).length
        const declinedCount = (haulRequest.typeId === this.exportHaulRequestTypeId ? haulRequest.importApprovals : haulRequest.exportApprovals).filter(approval => approval.haulRequestApprovalStatusId === this.declineApprovalStatusId).length

        if (haulRequest.importApprovals.length > (acceptedCount + declinedCount)) return 'New'
        if (acceptedCount > 0) return 'Accepted'
        if (declinedCount > 0) return 'Declined'
        return '-'
      }
      return haulRequest.exportStatus.status
    },
    clientType (haulRequest) {
      if (this.selectedCompanyProfile.companyTypeId === this.generalContractorCompanyTypeId && haulRequest.siteId !== this.selectedSiteId) {
        if (haulRequest.typeId === this.exportHaulRequestTypeId) return 'IMPORT'
        return 'EXPORT'
      }
      return haulRequest.type.name
    },
    formatDate (date) {
      return dateFromUTC(date)
    },
    showNewApprovalButton (haulRequest, buttonType) {
      if (this.selectedCompanyProfile.companyTypeId === this.haulingCompanyTypeId && haulRequest.typeId !== buttonType) {
        return true
      }
      if (buttonType === this.exportHaulRequestTypeId && this.selectedCompanyProfile.id === haulRequest.exportingContractorCompanyProfileId) {
        return this.selectedSiteId === haulRequest.siteId
      } else if (buttonType === this.importHaulRequestTypeId && this.selectedCompanyProfile.id === haulRequest.importingContractorCompanyProfileId) {
        return this.selectedSiteId === haulRequest.siteId
      }
      return false
    },
    disabledNewApprovalButton (haulRequest, buttonType) {
      if (this.selectedCompanyProfile.companyTypeId === this.siteManagementCompanyTypeId) return false
      if (buttonType === this.exportHaulRequestTypeId) {
        return haulRequest.totalVolume[0].allocatedVolume >= haulRequest.totalVolume[0].importingVolume || haulRequest.totalVolume[0].allocatedVolume >= haulRequest.totalVolume[0].acceptedVolume
      } else if (buttonType === this.importHaulRequestTypeId) {
        return haulRequest.totalVolume[0].allocatedVolume >= haulRequest.totalVolume[0].exportingVolume || haulRequest.totalVolume[0].allocatedVolume >= haulRequest.totalVolume[0].acceptedVolume
      }
      return true
    },
    syncExpanded () {
      this.expanded = this.haulRequests.filter(x => x.expanded)
    }
  }
}
</script>
<style>
  .container.unreactive-margins {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .expanded-row-content:hover {
    background-color: transparent !important;
  }
  .inverse-header-color.theme--light div.v-data-table__wrapper table thead.v-data-table-header {
    background-color: #555555;
  }
  .inverse-header-color.theme--light div.v-data-table__wrapper table thead.v-data-table-header tr th i {
    color: white !important;
  }
  .inverse-header-color.theme--light div.v-data-table__wrapper table thead.v-data-table-header tr th span {
    color: white;
  }
  .inverse-header-color.theme--dark div.v-data-table__wrapper table thead.v-data-table-header {
    background-color: #aaaaaa;
  }
  .inverse-header-color.theme--dark div.v-data-table__wrapper table thead.v-data-table-header tr th i {
    color: black !important;
  }
  .inverse-header-color.theme--dark div.v-data-table__wrapper table thead.v-data-table-header tr th span {
    color: black;
  }
</style>
