<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-dialog v-model="formOpen" persistent style="width:80%" width="50%" min-width="600px">
        <v-card style="width:100%">
          <v-card-title>Select Segment and Depth to Import to</v-card-title>
          <v-card-text>
            <v-container style="width:100%">
              <v-form v-model="valid" ref="form">
                <v-row class="mt-1">
                  <v-col cols="4">
                    <v-text-field label="Import Site" :value="site ? site.name : ''" disabled />
                  </v-col>

                  <v-col cols="4">
                    <v-select
                      label="Segment"
                      :items="segments"
                      v-model="segment"
                      item-text="name"
                      :item-value="(item) => item"
                      @input="loadDepths()"
                      :rules="[rules.requiredObject]"
                      ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      label="Depth"
                      :disabled="!segment || !segment.id"
                      :items="segment && segment.segmentDepths ? segment.segmentDepths : []"
                      v-model="submitData.toSegmentDepthId"
                      :item-text="(item) => `${item.name} (${item.depth.name})`"
                      item-value="id"
                      :rules="[rules.required]"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row class="no-gutters">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn text class="mr-2"  @click="closeForm">Close</v-btn>
                <v-btn variant="outlined" color="success" elevation="0" @click="acceptApproval">Accept</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
export default {
  components: { },
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    approvalId: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    formOpen: false,
    defaultSubmitData: {
      toSegmentDepthId: null
    },
    rules: {
      required: value => !!value || 'Required.',
      requiredObject: value => !!Object.keys(value).length || 'Required.'
    },
    valid: false,
    submitData: {},
    site: {},
    segments: [],
    segment: {}
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    })
  },
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
        if (!value) this.resetForm()
      }
    },
    approvalId: {
      immediate: true,
      handler (value) {
        if (value) this.loadSiteData()
        else this.site = {}
      }
    }
  },
  methods: {
    acceptApproval () {
      if (!this.$refs.form.validate()) return
      const data = { ...this.submitData }
      this.$emit('selectDepthAndApprove', data, (res) => this.processResult(res))
    },
    processResult (res) {
      if (res === true) {
        this.$store.dispatch('app/showSuccess', 'Approval successfully accepted')
        this.closeForm()
      } else {
        this.$store.dispatch('app/showError', 'There was an issue with accepting this approval. Please contact support.')
      }
    },
    closeForm () {
      this.resetForm()
      this.$emit('closeForm')
    },
    resetForm () {
      this.submitData = { ...this.defaultSubmitData }
      this.segment = {}
      this.segments = []
    },
    async loadSegments () {
      const res = await this.$api.segments.read(createPaginationObject(null, null, [{ name: 'SiteId', values: [this.site.id] }]))
      this.segments = res.segments.map(seg => {
        return { ...seg, segmentDepths: [] }
      })
      if (this.segments.length === 1) {
        this.segment = this.segments[0]
        this.loadDepths()
      }
    },
    async loadDepths () {
      const res = await this.$api.segmentDepths.read(createPaginationObject(null, null, [{ name: 'SegmentId', values: [this.segment.id] }, { name: 'Type', values: ['Recipient'] }]))
      this.segment.segmentDepths = res.segmentDepths
      if (this.segment.segmentDepths.length === 1) {
        this.submitData.toSegmentDepthId = this.segment.segmentDepths[0].id
      }
    },
    openCompanySearch () {
      this.companySearchFormOpen = true
    },
    async loadSiteData () {
      await this.loadSite()
      this.loadSegments()
    },
    async loadSite () {
      const res = await this.$api.sites.read(createPaginationObject(null, null, [{ name: 'ApprovalId', values: [this.approvalId] }]))
      this.site = res.sites[0]
    }
  }
}
</script>

<style>
  .v-dialog > .v-overlay__content {
    width:80%;
  }
</style>
