<template>
  <div>
    <haul-approval-depth-selection-form :approvalId="selectedApproval ? selectedApproval.id : null" :formIsOpen="formIsOpen" @closeForm="formIsOpen = false; selectedApproval = null" @selectDepthAndApprove="(data, callback) => selectDepthAndApprove(data, callback)" />
    <v-data-table
      :headers="headers"
      :items="approvals"
        item-key="id"
    >
        <template v-slot:item="{ item }">
          <tr>
            <td class="d-block d-sm-table-cell" v-for="(header, headerIndex) in headers" :key="`segment-${item.segmentId}-depth-${item.id}-field-${headerIndex}`">
              <template v-if="header.value == 'unitOfMeasurement'">
                {{item.loadUnitOfMeasure.shortName}}
              </template>
              <template v-else-if="header.value == 'code'">
                <span class="font-weight-bold">{{ item.code }}</span>
              </template>
              <template v-else-if="header.value == 'status'">
                <div class="d-flex justify-center">
                  <v-chip small :color="chipColor(approvalStatus(item))">{{ approvalStatus(item) }}</v-chip>
                </div>
              </template>
              <template v-else-if="header.value == 'siteManagement'">
                {{ item.site && item.site.company ? item.site.company.name : '-' }}
              </template>
              <template v-else-if="header.value == 'importSite'">
                {{ item.site ? `${item.site.name} (${item.site.siteCode})` : '-' }}{{ item.site && item.site.address ? ` - ${formatAddress(item.site.address)}` : '' }}
              </template>
              <template v-else-if="header.value == 'segmentDepth'">
                {{ segmentDepthReadout(item) }}
              </template>
              <template v-else-if="header.value == 'totalVolume'">
                {{ (item.loadVolume * item.loadQuantity).toLocaleString() }}
              </template>
              <template v-else-if="header.value == 'issuedBy'">
                {{ `${item.issuedBy.firstName} ${item.issuedBy.lastName}` }}
              </template>
              <template v-else-if="header.value == 'issuedOn'">
                {{ formatDate(item.issuedOn) }}
              </template>
              <template v-else-if="header.value == 'approvedBy'">
                {{ item.processedBy ? `${item.processedBy.firstName} ${item.processedBy.lastName}` : '-' }}
              </template>
              <template v-else-if="header.value == 'approvedOn'">
                {{ item.processedOn == '0001-01-01T00:00:00' ? '-' : formatDate(item.processedOn) }}
              </template>
              <template v-else-if="header.value == 'actions'">
                <div class="d-flex justify-end align-center">
                  <!--buttons for owner of haul request-->
                  <v-btn
                    v-if="
                      (selectedCompanyProfile.id == haulRequest.exportingContractorCompanyProfileId && item.isExport)
                      || (selectedCompanyProfile.id == haulRequest.importingContractorCompanyProfileId && !item.isExport)
                      || (selectedCompanyProfile.id == item.haulingCompanyProfileId && item.isExport != haulRequest.isExport)"
                    @click="editApproval(item)"
                    :disabled="item.isSent"
                    outlined
                    small
                    class="mr-1"
                    color="primary"
                    >Edit</v-btn>
                  <v-btn
                    v-if="
                        (selectedCompanyProfile.id == haulRequest.exportingContractorCompanyProfileId && item.isExport)
                        || (selectedCompanyProfile.id == haulRequest.importingContractorCompanyProfileId && !item.isExport)
                        || (selectedCompanyProfile.id == item.haulingCompanyProfileId && item.isExport != haulRequest.isExport)"
                    @click="sendApproval(item)"
                    :disabled="item.isSent"
                    outlined
                    small
                    color="success"
                    >Send for Approval</v-btn>
                  <!--buttons for hauling manager-->
                  <v-btn
                    v-if="selectedCompanyProfile.companyTypeId == haulingCompanyTypeId && item.isExport == haulRequest.isExport"
                    :disabled="item.haulRequestApprovalStatusId != newApprovalStatusId"
                    @click="processApproval(item, acceptApprovalStatusId)"
                    outlined
                    small
                    color="success"
                    >Accept</v-btn>
                  <v-btn
                    v-if="selectedCompanyProfile.companyTypeId == haulingCompanyTypeId && item.isExport == haulRequest.isExport"
                    :disabled="item.haulRequestApprovalStatusId != newApprovalStatusId"
                    @click="declineFormData.approval = item; declineFormData.openDialog = true; declineFormData.reasonForDeclining = '';"
                    outlined
                    small
                    color="error"
                    class="ml-1"
                    >Decline</v-btn>
                  <!--buttons for site manager that does not own the haul request-->
                  <v-btn
                    v-if="
                      selectedCompanyProfile.companyTypeId == generalContractorTypeId
                      && item.isExport != haulRequest.isExport
                      && selectedCompanyProfile.id == (item.isExport ? item.exportingContractorCompanyProfileId : item.importingContractorCompanyProfileId)"
                    :disabled="item.haulRequestApprovalStatusId != newApprovalStatusId"
                    @click="processApproval(item, acceptApprovalStatusId)"
                    outlined
                    small
                    color="success"
                    >Accept</v-btn>
                  <v-btn
                    v-if="
                      selectedCompanyProfile.companyTypeId == generalContractorTypeId
                      && item.isExport != haulRequest.isExport
                      && selectedCompanyProfile.id == (item.isExport ? item.exportingContractorCompanyProfileId : item.importingContractorCompanyProfileId)"
                    :disabled="item.haulRequestApprovalStatusId != newApprovalStatusId"
                    @click="declineFormData.approval = item; declineFormData.openDialog = true; declineFormData.reasonForDeclining = '';"
                    outlined
                    small
                    color="error"
                    class="ml-1"
                    >Decline</v-btn>
                </div>
              </template>
              <template v-else>
                {{ item[header.value] ? item[header.value] : '-' }}
              </template>
            </td>
          </tr>
        </template>
    </v-data-table>
    <v-dialog v-model="declineFormData.openDialog"
      width="550" >
      <v-card>
        <v-card-title>
          <span class="text-h4">Decline approval{{ declineFormData.approval ? ' ' + declineFormData.approval.code : '' }}?</span>
        </v-card-title>
        <v-card-text>
          <v-text-field label="Reason for Declining (optional)" v-model="declineFormData.reasonForDeclining">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-row class="no-gutters">
            <v-col cols="12" class="d-flex justify-end">
              <v-btn @click="declineFormData.openDialog = false; declineFormData.approval = null" text>Cancel</v-btn>
              <v-btn
                @click="processApproval(declineFormData.approval, declineApprovalStatusId, declineFormData.reasonForDeclining)"
                color="error"
                class="ml-1"
                elevation="0"
                >Confirm</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { chipColorClass } from '@/helpers/StylingHelper.js'
import { dateFromUTC, formatAddress } from '@/helpers/FormatHelper.js'
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex';
import HaulApprovalDepthSelectionForm from './HaulApprovalDepthSelectionForm.vue'

export default {
  components: { HaulApprovalDepthSelectionForm },
  props: {
    approvals: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'export'
    },
    haulRequest: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    generalContractorTypeId: 1,
    haulingCompanyTypeId: 2,
    newApprovalStatusId: 1,
    acceptApprovalStatusId: 2,
    declineApprovalStatusId: 3,

    formIsOpen: false,
    selectedApproval: null,

    declineFormData: {
      openDialog: false,
      approvalToDecline: null,
      reasonForDeclining: ''
    }
  }),
  computed: {
    ...mapState({
      selectedSiteId: (state) => state.site.selectedSiteId
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    headers () {
      const headers = []
      headers.push({ text: 'Approval Code', value: 'code', sortable: false })
      if ((this.type === 'export') !== this.haulRequest.isExport) {
        const actionCapitalized = this.type.charAt(0).toUpperCase() + this.type.slice(1)
        headers.push({ text: `${actionCapitalized} Company`, value: 'siteManagement', sortable: false })
        headers.push({ text: `${actionCapitalized}ing Site`, value: 'importSite', sortable: false })
        headers.push({ text: 'Segment - Depth', value: 'segmentDepth', sortable: false })
      }
      headers.push({ text: 'Load QTY', value: 'loadQuantity', sortable: false })
      headers.push({ text: 'Volume Per Load', value: 'loadVolume', sortable: false })
      headers.push({ text: 'UOM', value: 'unitOfMeasurement', sortable: false })
      headers.push({ text: 'Total Volume', value: 'totalVolume', sortable: false })
      headers.push({ text: 'Status', value: 'status', sortable: false, align: 'center' })
      headers.push({ text: 'Issued By', value: 'issuedBy', sortable: false })
      headers.push({ text: 'Issued On', value: 'issuedOn', sortable: false })
      headers.push({ text: 'Approved By', value: 'approvedBy', sortable: false })
      headers.push({ text: 'Approval Date', value: 'approvedOn', sortable: false })
      headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'end' })
      return headers
    }
  },
  watch: {
  },
  methods: {
    async loadApprovals () {
    },
    deleteApproval (item) {
      console.log(item)
    },
    chipColor (name) {
      return chipColorClass(name)
    },
    formatDate (date) {
      return dateFromUTC(date)
    },
    processApproval (approval, statusId, decisionNote) {
      // from hauling manager's POV
      if (this.selectedCompanyProfile.companyTypeId === this.haulingCompanyTypeId) {
        if (!this.declineFormData.openDialog && !confirm(`Are you sure you want to ${statusId === this.acceptApprovalStatusId ? 'accept' : 'decline'} approval ${approval.code}`)) return
        this.$emit('processApproval', approval, statusId, decisionNote)
        this.declineFormData.openDialog = false
        this.declineFormData.approval = null
        this.declineFormData.decisionNote = ''
      } else if (statusId === this.declineApprovalStatusId) {
        this.$emit('processApproval', approval, statusId, decisionNote)
        this.declineFormData.openDialog = false
        this.declineFormData.approval = null
        this.declineFormData.decisionNote = ''
      } else {
        this.formIsOpen = true
        this.selectedApproval = approval
      }
    },
    sendApproval (approval) {
      if (!confirm(`Please confirm that approval ${approval.code} is ready to send. You cannot change it after it has been sent.`)) return
      this.$emit('sendApproval', approval)
    },
    editApproval (approval) {
      console.log(approval)
      this.$emit('editApproval', approval)
    },
    selectDepthAndApprove (data, callback) {
      console.log({ ...this.selectedApproval, siteSegmentDepthId: data.toSegmentDepthId })
      console.log(callback)
      // eslint-disable-next-line standard/no-callback-literal
      this.$emit('selectDepthAndApprove', { ...this.selectedApproval, siteSegmentDepthId: data.toSegmentDepthId }, (res) => callback(res))
    },
    formatAddress (address) {
      return formatAddress(address)
    },
    approvalStatus (approval) {
      if (approval.haulRequestApprovalStatusId === this.newApprovalStatusId && approval.isSent) return 'Sent'
      return approval.haulRequestApprovalStatus.status
    },
    segmentDepthReadout (approval) {
      if (!approval.siteSegmentDepth) return 'TBD'
      const split = approval.siteSegmentDepth.code.split('-')
      return `${split[1]} - ${split[2]}`
    }
  }
}
</script>
