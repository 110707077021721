<template>
  <v-row justify="center">
    <v-col cols="12">
      <site-search-form ref="importSiteSearchForm" :formIsOpen="importSiteSearchFormOpen" :filters="[{name: 'Flow', values: [approvalData.isExport ? 'Exporting' : 'Importing']}]" @setImportSite="(emittedsite) => site = emittedsite" @closeForm="importSiteSearchFormOpen = false"/>
      <v-dialog v-model="formOpen" persistent style="width:80%" width="50%" min-width="600px">
        <v-card style="width:100%">
          <v-card-title>{{approvalData.existingApproval ? 'Update Existing' : 'Create New' }} Approval</v-card-title>
          <v-card-text>
            <v-container style="width:100%">
              <v-form v-model="valid" ref="form">
                <span class="text-subtitle-1">Hauling Request Details</span>
                <v-row class="mt-1">
                  <v-col cols="4">
                    <v-text-field
                      label="Extraction Site"
                      :value="approvalData && approvalData.haulRequest && approvalData.haulRequest.segmentDepth && approvalData.haulRequest.segmentDepth.segment && approvalData.haulRequest.segmentDepth.segment.site ? approvalData.haulRequest.segmentDepth.segment.site.name : 'Error'"
                      disabled />
                  </v-col>

                  <v-col cols="4">
                    <v-text-field
                      label="Segment"
                      :value="approvalData && approvalData.haulRequest && approvalData.haulRequest.segmentDepth && approvalData.haulRequest.segmentDepth.segment ? approvalData.haulRequest.segmentDepth.segment.name : 'Error'"
                      disabled />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Depth"
                      :value="approvalData && approvalData.haulRequest && approvalData.haulRequest.segmentDepth ? `${approvalData.haulRequest.segmentDepth.name} (${approvalData.haulRequest.segmentDepth.depth.name})` : 'Error'"
                      disabled />
                  </v-col>
                </v-row>
                <v-row class="mt-1" cols="6">
                  <v-col v-if="selectedCompanyProfile.companyTypeId == haulingCompanyTypeId">
                    <v-text-field
                      :label="`${approvalData.isExport ? 'Exporting' : 'Importing'} Site`"
                      readonly
                      style="cursor: pointer"
                      @click="openImportSiteSearch()"
                      :value=" site.id ? `${site.name} (${site.company.name}) - ${formatAddress(site.address)}` : ''"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col v-else cols="6">
                    <v-text-field
                      label="Hauling Contractor"
                      :value="existingHaulingContractor ? `${existingHaulingContractor.name}` : 'Loading...'"
                      disabled />
                  </v-col>
                </v-row>
                <span class="text-subtitle-1">New Hauling Approval</span>
                <v-row class="mt-1">
                  <v-col cols="3">
                    <v-text-field
                      v-model="computedLoadQuantity"
                      @keypress="(event) => filter(event)"
                      label="Number of Loads"
                      hint="Each load is 10 m³"
                      :rules="selectedCompanyProfile.companyTypeId == haulingCompanyTypeId  ? [rules.required, rules.maximum] : [rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-row class="no-gutters">
              <v-col cols="12" class="d-flex justify-end">
                <v-btn text class="mr-2"  @click="closeForm">Close</v-btn>
                <v-btn variant="outlined" @click="submitHaulRequestApproval">Save</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'// import { mapGetters, mapState, mapMutations } from 'vuex'
import SiteSearchForm from '@/components/sites/SiteSearchForm'
import { formatAddress } from '@/helpers/FormatHelper.js'
import { createPaginationObject } from '@/helpers/PaginationHelper.js'
export default {
  components: { SiteSearchForm },
  props: {
    formIsOpen: {
      type: Boolean,
      default: false
    },
    approvalData: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    formOpen: false,
    defaultSubmitData: {
      id: null,
      haulingRequestId: null,
      haulStartDate: null,
      haulEndDate: null,
      isExport: true,
      loadQuantity: 0,
      loadVolume: 10,
      loadUnitOfMeasureId: 3
    },
    originalEditingLoadQuantity: 0,

    existingHaulingContractor: null,
    valid: false,
    submitData: {},
    site: {},
    haulingCompanyTypeId: 2,
    importSiteSearchFormOpen: false
  }),
  computed: {
    ...mapState({
      darkMode: (state) => state.app.isDarkMode,
      companies: (state) => state.comp.companies
    }),
    ...mapGetters({
      findClaim: 'auth/findClaim',
      selectedCompanyProfile: 'auth/selectedCompanyProfile'
    }),
    computedLoadQuantity: {
      get () {
        return this.submitData.loadQuantity
      },
      set (value) {
        this.$set(this.submitData, 'loadQuantity', (/^\d+$/.test(value) ? Number(value) : 0))
      }
    },
    rules () {
      const rules = {
        required: value => !!value || 'Required.',
        requiredObject: value => !!Object.keys(value).length || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Must be a valid e-mail.'
        },
        maximum: value => {
          const pattern = /^\d+$/
          return (pattern.test(value) && Number(value) <= this.volumeLimit) || `Must not exceed approved export volume (${this.volumeLimit} loads)`
        }

      }
      return rules
    },
    volumeLimit () {
      if (!this.approvalData || !this.approvalData.haulRequest || !this.approvalData.haulRequest.totalVolume) return 0
      const totalVolume = this.approvalData.haulRequest.totalVolume.find(x => x.unitOfMeasurement.id === this.submitData.loadUnitOfMeasureId)
      return (totalVolume.acceptedVolume - totalVolume.allocatedVolume) / this.submitData.loadVolume + this.originalEditingLoadQuantity
    }
  },
  watch: {
    formIsOpen: {
      handler (value) {
        this.formOpen = value
        if (value === false) this.resetForm()
      }
    },
    approvalData: {
      immediate: true,
      handler (value) {
        this.resetForm()
        this.submitData.isExport = value.isExport
        if (value.existingApproval) {
          this.originalEditingLoadQuantity = value.existingApproval.loadQuantity
          this.submitData = { ...value.existingApproval, haulingRequestId: value.existingApproval.haulRequestId }
          this.site = value.existingApproval.site
        } else if (value && value.haulRequest && value.haulRequest.id) {
          this.submitData.haulingRequestId = value.haulRequest.id
          if (value.haulRequest.isOwner) this.site = value.haulRequest.site
        }
        this.loadExistingHaulingCompany()
      }
    }
  },
  methods: {
    submitHaulRequestApproval () {
      if (!this.$refs.form.validate()) return
      const data = { ...this.submitData, siteId: this.site.id }
      this.$emit('submitForm', data)
    },
    closeForm () {
      this.$emit('closeForm')
      this.resetForm()
    },
    resetForm () {
      this.submitData = { ...this.defaultSubmitData }
      this.existingHaulingContractor = null
      this.site = {}
      this.originalEditingLoadQuantity = 0
    },
    openImportSiteSearch () {
      this.importSiteSearchFormOpen = true
    },
    filter (evt) {
      evt = (evt) || window.event
      const expect = evt.target.value.toString() + evt.key.toString()

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    formatAddress (address) {
      return formatAddress(address)
    },
    async loadExistingHaulingCompany () {
      if (this.approvalData && this.approvalData.haulRequest && this.approvalData.haulRequest.haulingCompanyProfileId) {
        const res = await this.$api.companies.read(createPaginationObject(null, null, [{ name: 'CompanyProfileId', values: [this.approvalData.haulRequest.haulingCompanyProfileId] }], ['Address', 'Phone', 'Email']))
        this.existingHaulingContractor = res.companies[0]
      }
    }

  }
}
</script>

<style>
  .v-dialog > .v-overlay__content {
    width:80%;
  }
</style>
